import React, { Component } from 'react';
import HeroImg from './img/pictures/mfg.jpg';

import './tailwind.generated.css';

class NewHero extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div class="relative bg-gray-50 overflow-hidden">
                <div class="hidden sm:block sm:absolute sm:inset-y-0 sm:h-full sm:w-full">
                    <div class="relative h-full max-w-screen-xl mx-auto">
                        <svg class="absolute right-full transform translate-y-1/4 translate-x-1/4 lg:translate-x-1/2" width="404" height="784" fill="none" viewBox="0 0 404 784">
                            <defs>
                                <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                    <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                                </pattern>
                            </defs>
                            <rect width="404" height="784" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
                        </svg>
                        <svg class="absolute left-full transform -translate-y-3/4 -translate-x-1/4 md:-translate-y-1/2 lg:-translate-x-1/2" width="404" height="784" fill="none" viewBox="0 0 404 784">
                            <defs>
                                <pattern id="5d0dd344-b041-4d26-bec4-8d33ea57ec9b" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                    <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                                </pattern>
                            </defs>
                            <rect width="404" height="784" fill="url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)" />
                        </svg>
                    </div>
                </div>

                <div class="relative pt-6 pb-12 sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32">
                    <div class="max-w-screen-xl mx-auto px-4 sm:px-6">
                        <nav class="relative flex items-center justify-between sm:h-10 md:justify-center">
                            <div class="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
                                <div class="flex items-center justify-between w-full md:w-auto">
                                    <a href="#" aria-label="Home">
                                        <p class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-indigo-600 sm:text-3xl sm:leading-10">DevFlight</p>
                                    </a>
                                    <div class="-mr-2 flex items-center md:hidden">
                                        <button type="button" class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out" id="main-menu" aria-label="Main menu" aria-haspopup="true">
                                            <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0">
                            </div>
                        </nav>
                    </div>
                    <main class="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 xl:mt-28">
                        <div class="text-center">
                            <h2 class="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
                                Automate your
                        <br class="xl:hidden" />
                                <span class="text-indigo-500"> security with </span>
                                <span class="text-indigo-800">DevFlight</span>
                            </h2>
                            <p class="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                                DevFlight is a powerful, easy to use tool to automate security playbooks and quickly mitigate organizational risk.
                        </p>
                            <div class="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
                                <div class="rounded-md shadow">
                                    <form name="contact-footer" method="POST" className="sm:flex">
                                        <input type="hidden" name="form-name" value="contact" />
                                        <input aria-label="Email address" required className="appearance-none w-full px-5 py-3 border border-transparent text-base leading-6 rounded-md text-gray-900 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 transition duration-150 ease-in-out sm:max-w-xs" placeholder="Enter your email" type="email" name="email" />
                                        <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                                            <button className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-400 focus:outline-none focus:bg-indigo-400 transition duration-150 ease-in-out">
                                                Request demo
                                 </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        );
    }
}

export default NewHero;
