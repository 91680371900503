import React from 'react';
import Hero from './Hero';
import NewHero from './NewHero';
import Integrations from './Integrations';
import Logos from './Logos';
import Features from './Features';
import Pricing from './Pricing';
import Examples from './Examples';
import NewPricing from './NewPricing';
import CTA from './CTA';
import Footer from './Footer';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/">
          <div>
            <NewHero />
            <Features />
            <Integrations />
            <CTA />
            <Examples />
            {/* <NewPricing /> */}
            <Footer />
          </div>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
