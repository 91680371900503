import React, { Component } from 'react';
import './tailwind.generated.css';
import Img1 from './img/pictures/playbook1.png'
class CTA extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <div className="py-12 bg-gray-100">
          <h1 class="mt-2 mb-6 text-3xl leading-8 font-bold tracking-tight text-gray-900 sm:text-3xl sm:leading-10 text-center">Learn How DevFlight can protect your organization today.</h1>
          <div class="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">

            <div class="rounded-md shadow">

              <form name="contact-footer" method="POST" className="sm:flex">
                <input type="hidden" name="form-name" value="contact" />
                <input aria-label="Email address" required className="appearance-none w-full px-5 py-3 border border-transparent text-base leading-6 rounded-md text-gray-900 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 transition duration-150 ease-in-out sm:max-w-xs" placeholder="Enter your email" type="email" name="email" />
                <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                  <button className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-400 focus:outline-none focus:bg-indigo-400 transition duration-150 ease-in-out">
                    Request demo
                </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="relative max-w-8xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <div class="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen"></div>
          <div class="mx-auto text-base max-w-prose lg:max-w-none">
            {/* <p class="text-base leading-6 text-indigo-600 font-semibold tracking-wide uppercase">Case Study</p> */}
            <h1 class="mt-2 mb-8 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">Our Security Products</h1>
          </div>
          <div class="lg:grid lg:grid-cols-2 lg:gap-8">
            <div class="relative mb-8 lg:mb-0 lg:row-start-1 lg:col-start-2">
              <svg class="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20" width="404" height="384" fill="none" viewBox="0 0 404 384">
                <defs>
                  <pattern id="de316486-4a29-4312-bdfc-fbce2132a2c1" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width="404" height="384" fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
              </svg>
              <div class="relative text-base mx-auto max-w-prose lg:max-w-none">
                <figure>
                  <div class=" w-full ">
                    <img src={Img1} alt="" width="2184" height="1376" class="rounded-lg shadow-lg object-cover    xl:static " />
                  </div>
                </figure>
              </div>
            </div>
            <div>
              <div class="text-base max-w-prose mx-auto lg:max-w-none">
                <p class="text-lg leading-7 text-gray-500 mb-5">Our Security Automation Engine allows IT and security teams to stop chasing after risks and automate the most difficult and repeated tasks.</p>
                <p class="text-lg leading-7 text-gray-500 mb-5">Existing tools can take weeks to setup and still require large teams to be continuously monitoring and operating.  DevFlight brings simplicity to security, allowing organizations to get setup with
              quickly and automate away frequently repeated security tasks.</p>
                <p class="text-lg leading-7 text-gray-500 mb-5">Open source and free to use, transparency and performance are built into the core of our product.  Sign up for our early access list and see how DevFlight can improve your organization security.</p>
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

export default CTA;