import React, { Component } from 'react';
import './tailwind.generated.css';

class Examples extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <div class="py-12 bg-gray-200">
          <div class="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-screen-xl lg:px-8">
            <div className="lg:text-center">
              {/* <p className="text-base leading-6 text-indigo-600 font-semibold tracking-wide uppercase"> Example Use Cases </p> */}
              <h2 class="text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
                Reacting to security risks should be automatic and easy, not time-consuming and labor intensive.
            </h2>
              {/* <p class="mt-4 max-w-3xl mx-auto text-center text-xl leading-7 text-gray-500">
              Playbooks represent logical actions taken in response to a security event, automated and automatic.  Example use-cases:
                        </p> */}
            </div>
            <div class="xl:grid lg:grid-cols-3 lg:gap-12 space-x-6">
              <div class="mt-8">
                <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                  </svg>
                </div>
                <div class="mt-5">
                  <h5 class="text-lg leading-6 font-medium text-gray-900">Malicious Login Detected</h5>
                  <p class="mt-2 text-base leading-6 text-gray-500">
                    Investigate origin of login, quarantine or shutdown servers, and notify / alert relevant team members.
              </p>
                </div>
              </div>
              <div class="mt-8">
                <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
                  </svg>
                </div>
                <div class="mt-5">
                  <h5 class="text-lg leading-6 font-medium text-gray-900">Leaked Credentials</h5>
                  <p class="mt-2 text-base leading-6 text-gray-500">
                    Automatically reset and rotate relevant credentials, notify team members, and update internal security logs.
              </p>
                </div>
              </div>
              <div class="mt-8">
                <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
                  </svg>
                </div>
                <div class="mt-5">
                  <h5 class="text-lg leading-6 font-medium text-gray-900">Phishing E-mail</h5>
                  <p class="mt-2 text-base leading-6 text-gray-500">
                    Immediately isolate message, notify recipient of attempt, blacklist sender, and trigger updates to install any security patches.
              </p>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="relative bg-white">
          <div class="lg:absolute lg:inset-0">
            <div class="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
              <img class="h-56 w-full object-cover lg:absolute lg:h-full" src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-1.2.1&auto=format&fit=crop&w=1567&q=80" alt="" />
            </div>
          </div>
          <div class="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
            <div class="lg:col-start-2 lg:pl-8">
              <div class="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0">
                {/* <p class="leading-6 text-indigo-600 font-semibold tracking-wide uppercase">Work with us</p> */}
                <h1 class="mt-2 mb-8 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">Our Company and Our Technology</h1>
                <p class="text-lg leading-7 text-gray-500 mb-5">Designed by engineering leaders from preeminent technology companies including Booz Allen Hamilton, Seagate Technologies, and Jibo and backed by top-tier investors.
                DevFlight aims to build the next generation of security automation tools.</p>
                <p class="text-lg leading-7 text-gray-500 mb-5">DevFlight allows organizations to create easy-to-setup security automations that react instantly to any threats
                to your organization.  These automations integrate with all existing security and IT systems to respond intelligently to IT risks and execute all the manual tasks your IT and security teams do.</p>
                <p class="text-lg leading-7 text-gray-500 mb-5">Years of security and automation experience led us develop the next generation of tools to protect organizations. </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Examples;
