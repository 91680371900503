import React, { Component } from 'react';
import './tailwind.generated.css';

class Integrations extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div class="bg-white">
        <div class="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          {/* <div class="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center"> */}
          <div>
            <h2 class="text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
              Integrates with your existing security tools
              </h2>
            {/* <p class="mt-3 max-w-3xl text-lg leading-7 text-gray-500">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et, egestas tempus tellus etiam sed. Quam a scelerisque amet ullamcorper eu enim et fermentum, augue.
              </p> */}
            {/* <div class="mt-8 sm:flex">
              <div class="rounded-md shadow">
                <a href="#" class="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                  Create Account
                  </a>
              </div>
              <div class="mt-3 sm:mt-0 sm:ml-3">
                <a href="#" class="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:text-indigo-600 hover:bg-indigo-50 focus:outline-none focus:shadow-outline focus:border-indigo-300 transition duration-150 ease-in-out">
                  Contact Us
                  </a>
              </div>
            </div> */}
          </div>
          <div class="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-8">
            <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
              <img class="h-16" src="https://d0.awsstatic.com/logos/powered-by-aws.png" alt="AWS" />
            </div>
            <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
              <img class="h-6" src="https://upload.wikimedia.org/wikipedia/commons/1/11/CarbonBlack-Logo-Primary-Black.png" alt="Carbon Black" />
            </div>
            <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
              <img class="h-14" src="https://avatars3.githubusercontent.com/u/27865?s=200&v=4" alt="Domainr" />
            </div>
            <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
              <img class="h-16" src="https://avatars0.githubusercontent.com/u/1004560?s=200&v=4" alt="DomainTools" />
            </div>
            <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
              <img class="h-12" src="https://www.flaticon.com/svg/static/icons/svg/25/25231.svg" alt="GitHub" />
            </div>
            <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
              <img class="h-10" src="https://upload.wikimedia.org/wikipedia/commons/d/dc/Gsuite-logo.png" alt="GSuite" />
            </div>
            <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
              <img class="h-8" src="https://www.atlassian.com/dam/jcr:93075b1a-484c-4fe5-8a4f-942710e51760/Atlassian-horizontal-blue@2x-rgb.png" alt="Jira" />
            </div>
            <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
              <img class="h-10" src="https://upload.wikimedia.org/wikipedia/commons/3/38/Metasploit_logo_and_wordmark.png" alt="MetaSploit" />
            </div>
            <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
              <img class="h-10" src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/0f/PagerDuty-GreenRGB.svg/512px-PagerDuty-GreenRGB.svg.png" alt="PagerDuty" />
            </div>
            <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
              <img class="h-8" src="https://upload.wikimedia.org/wikipedia/commons/6/68/ServiceNow_Logo_2018.png" alt="Servicenow" />
            </div>
            <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
              <img class="h-12" src="https://assets.brandfolder.com/pl546j-7le8zk-6gwiyo/v/2925175/view@2x.png?v=1592396061" alt="Slack" />
            </div>
            <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
              <img class="h-12" src="https://upload.wikimedia.org/wikipedia/commons/f/f8/Splunk_logo.png" alt="Splunk" />
            </div>
            <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
              <img class="h-10" src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/62/VirusTotal_Logo.svg/512px-VirusTotal_Logo.svg.png" alt="VirusTotal" />
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
      // <div class="bg-white">
      //   <div class="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
      //     <div class="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
      //       <h2 class="text-3xl leading-9 font-extrabold text-white">
      //         Integrates with your existing security tools
      //       </h2>
      //       <div class="flow-root mt-8 lg:mt-0">
      //         <div class="-mt-4 -ml-8 flex flex-wrap justify-between lg:-ml-4">
      //           <div class="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
      //             <img class="h-16" src="https://d0.awsstatic.com/logos/powered-by-aws.png" alt="AWS" />
      //           </div>
      //           <div class="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
      //             <img class="h-6" src="https://upload.wikimedia.org/wikipedia/commons/1/11/CarbonBlack-Logo-Primary-Black.png" alt="Carbon Black" />
      //           </div>
      //           <div class="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
      //             <img class="h-14" src="https://avatars3.githubusercontent.com/u/27865?s=200&v=4" alt="Domainr" />
      //           </div>
      //           <div class="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
      //             <img class="h-16" src="https://avatars0.githubusercontent.com/u/1004560?s=200&v=4" alt="DomainTools" />
      //           </div>
      //           <div class="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
      //             <img class="h-12" src="https://www.flaticon.com/svg/static/icons/svg/25/25231.svg" alt="GitHub" />
      //           </div>
      //           <div class="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
      //             <img class="h-10" src="https://upload.wikimedia.org/wikipedia/commons/d/dc/Gsuite-logo.png" alt="GSuite" />
      //           </div>
      //           <div class="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
      //             <img class="h-8" src="https://www.atlassian.com/dam/jcr:93075b1a-484c-4fe5-8a4f-942710e51760/Atlassian-horizontal-blue@2x-rgb.png" alt="Jira" />
      //           </div>
      //           <div class="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
      //             <img class="h-10" src="https://upload.wikimedia.org/wikipedia/commons/3/38/Metasploit_logo_and_wordmark.png" alt="MetaSploit" />
      //           </div>
      //           <div class="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
      //             <img class="h-10" src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/0f/PagerDuty-GreenRGB.svg/512px-PagerDuty-GreenRGB.svg.png" alt="PagerDuty" />
      //           </div>
      //           <div class="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
      //             <img class="h-8" src="https://upload.wikimedia.org/wikipedia/commons/6/68/ServiceNow_Logo_2018.png" alt="Servicenow" />
      //           </div>
      //           <div class="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
      //             <img class="h-12" src="https://assets.brandfolder.com/pl546j-7le8zk-6gwiyo/v/2925175/view@2x.png?v=1592396061" alt="Slack" />
      //           </div>
      //           <div class="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
      //             <img class="h-12" src="https://upload.wikimedia.org/wikipedia/commons/f/f8/Splunk_logo.png" alt="Splunk" />
      //           </div>
      //           <div class="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
      //             <img class="h-10" src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/62/VirusTotal_Logo.svg/512px-VirusTotal_Logo.svg.png" alt="VirusTotal" />
      //           </div>
      //         </div>
      //       </div>
      //       <h2 class="text-2xl leading-9 font-extrabold text-white">
      //         Developer SDK facilitates quick addition of new integrations.
      //       </h2>
      //     </div>
      //   </div>
      // </div>
    );
  }
}

export default Integrations;
